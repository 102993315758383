import { getAddressWithTmapApi } from 'api/consign/getAddressWithTmapApi';
import { debounce } from 'lodash';
import { useState } from 'react';

import { ML_CLASS } from './\bconst';
import type { FormattedSearchResult } from './type';

export const useAddressWithTmap = () => {
  const [searchText, setSearchText] = useState('');
  const [formattedSearchResult, setFormattedSearchResult] =
    useState<Array<FormattedSearchResult> | null>(null);

  const getAddressWithTMap = async (searchValue: string) => {
    try {
      const response = await getAddressWithTmapApi(searchValue);

      // response가 존재하지 않을경우: 정상적인 응답을 반환하지만, data가 없을경우
      if (response.status === 204 || !response) {
        setFormattedSearchResult([]);

        return;
      }

      if (response.status === 200) {
        const parsedResult = response.data.searchPoiInfo.pois.poi.map((poi: any) => {
          if (poi.newAddressList.newAddress[0]) {
            return {
              name: poi.name,
              lat: poi.newAddressList.newAddress[0].centerLat,
              lng: poi.newAddressList.newAddress[0].centerLon,
              roadNameAddress: poi.newAddressList.newAddress[0].fullAddressRoad,
              jibunAddress: `${poi.upperAddrName} ${poi.middleAddrName} ${poi.lowerAddrName} ${
                poi.detailAddrName
              } ${poi.mlClass === ML_CLASS.MOUNTAIN ? '산' : ' '}${
                poi.firstNo && `${poi.firstNo}`
              }${poi.secondNo && `-${poi.secondNo}`}`,
              legalDongCode: poi.legalDongCode,
            };
          }

          return {
            name: poi.name,
            lat: poi.noorLat,
            lng: poi.noorLon,
            roadNameAddress: poi.name,
            jibunAddress: `${poi.upperAddrName} ${poi.middleAddrName} ${poi.lowerAddrName} 
            ${poi.detailAddrName}
            ${poi.mlClass === ML_CLASS.MOUNTAIN ? '산' : ' '}${poi.firstNo && `${poi.firstNo}`}${
              poi.secondNo && `-${poi.secondNo}`
            }`,
            legalDongCode: poi.legalDongCode,
          };
        });

        setFormattedSearchResult(parsedResult);

        return;
      }
    } catch (error) {
      console.info('callTMapApi Error: ', error);
      alert('잠시 후 다시 시도해주세요.');
    }
  };

  const getAddress = debounce(async () => {
    if (!searchText) {
      return;
    }

    getAddressWithTMap(searchText);
  }, 300);

  const changeSearchText = (text: string) => {
    setSearchText(text);
  };

  return {
    searchText,
    getAddress,
    changeSearchText,
    formattedSearchResult,
  };
};
